import { action, observable } from 'mobx';
import jwtDecode from 'jwt-decode';

import { getMenu } from '@constants/menu';
import BaseStore from './BaseStore';
import {ROLE_ADMIN, ROLE_CLIENT, ROLE_CC_USER, ROLE_INVESTIGATOR, ROLE_SITE_MANAGER} from '@helpers/RoleTypes';

const menuCachedKey = 'menu';

export class MenuStoreBlueprint extends BaseStore {
  @observable menus: any = [];

  @action
  load = () => {
    const menu = localStorage.getItem(menuCachedKey);
    if (menu) {
      this.menus = JSON.parse(menu);
      return;
    }
    const token = localStorage.getItem('token');

    if (!token) {
      return;
    }
    const currentUser: any = jwtDecode(token);
    if (!currentUser) {
      return;
    }

    this.menus = getMenu(currentUser.role);

    if (this.menus) {
      localStorage.setItem('current-user', JSON.stringify(currentUser));
      this.addDataExportMenu(this.menus);
      this.addPerformanceDashboardMenu(this.menus);
      this.addSupportMenu(this.menus);
      localStorage.setItem(menuCachedKey, JSON.stringify(this.menus));
    }
  }

  addDataExportMenu = (navItems) => {
    const token: any = localStorage.getItem('current-user');
    if (!navItems || !token) {
      return;
    }

    const { role } = JSON.parse(token);
    if (role === ROLE_ADMIN || role === ROLE_CLIENT) {
      navItems.push({ url: '/data-export', copy: 'Data Export', label: 'Data Export', icon: 'candidates' });
    }
  }

  addSupportMenu = (navItems) => {
    const token: any = localStorage.getItem('current-user');
    if (!navItems || !token) {
      return;
    }

    const { role } = JSON.parse(token);
    if (role === ROLE_CC_USER || role === ROLE_CLIENT || role === ROLE_INVESTIGATOR || role === ROLE_SITE_MANAGER) {
      navItems.push({ url: '/support', copy: 'Support', label: 'Support', icon: 'phone' });
    }
  }

  addPerformanceDashboardMenu = (navItems) => {
    const token: any = localStorage.getItem('current-user');

    if (!navItems || !token) {
      return;
    }

    const { role } = JSON.parse(token);

    if (role === ROLE_CLIENT) {
      // navItems.push({ url: '/performance-dashboard', copy: 'Performance Dashboard', label: 'Performance Dashboard', icon: 'study' });
    }
  }

}

const MenuStore = new MenuStoreBlueprint();
export default MenuStore;

import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module'
import { withRouter } from 'react-router';

import Loading from 'components/@shared/loading-indicator';
import 'scss/components/body_content.scss';
import { myCandidates } from '@constants/menu';
import { ROLE_ADMIN, ROLE_SUPERUSER } from '@helpers/RoleTypes';

const Login = React.lazy(() => import('pages/login'));
const ResetPassword = React.lazy(() => import('pages/password-reset'));

const CallCentres = React.lazy(() => import('pages/call-centres'));
const EditCallCentre = React.lazy(() => import('pages/call-centres/edit'));
const CreateCallCentre = React.lazy(() => import('pages/call-centres/create'));

const Sites = React.lazy(() => import('pages/sites'));
const EditSite = React.lazy(() => import('./pages/sites/EditSite/edit'));
const CreateSite = React.lazy(() => import('./pages/sites/CreateSite/create'));
const ImportSites = React.lazy(() => import('pages/sites/import'));

const Studies = React.lazy(() => import('pages/studies'));
const EditStudy = React.lazy(() => import('pages/studies/edit'));
const CreateStudy = React.lazy(() => import('pages/studies/create'));

const Clients = React.lazy(() => import('pages/clients'));
const EditClient = React.lazy(() => import('pages/clients/edit'));
const CreateClient = React.lazy(() => import('pages/clients/create'));

const Candidates = React.lazy(() => import('pages/candidates'));
const EditCandidate = React.lazy(() => import('pages/candidates/edit'));
const CreateCandidate = React.lazy(() => import('pages/candidates/create'));

const MyProfile = React.lazy(() => import('pages/my-profile'));
const Users = React.lazy(() => import('pages/users'));
const EditUser = React.lazy(() => import('pages/users/edit'));
const ImportUsers = React.lazy(() => import('pages/users/import'));

const DataExport = React.lazy(() => import('pages/data-export'));
const Support = React.lazy(() => import('pages/support'));
const PerformanceDashboard = React.lazy(() => import('pages/performance-dashboard'));
const CsvUpload = React.lazy(() => import('pages/csv-upload'));
const StudySelection = React.lazy(() => import('pages/study-selection'));

const FourOhFour = React.lazy(() => import('pages/404'));

const { Component, Fragment, Suspense } = React;

const PrivateRoute = ({ authorised, ...rest }) => {
    return authorised
        ? (
            <>
                <Route
                    {...rest}
                    render={props => (<Component {...props} />)}
                />
            </>
        )
        : (
            <Route
                {...rest}
                component={props => (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)}
            />
        );
};

class Main extends Component {
    constructor(props) {
        super(props);
        const tagManagerArgs = {
            gtmId: 'GTM-MRNKPKL',
            dataLayerName: 'fazeDataLayer',
            userProject: 'faze',
            page: window.location.pathname + window.location.search
        };
        TagManager.initialize(tagManagerArgs);
        props.history.listen((location) => {
            tagManagerArgs.page = window.location.pathname + window.location.search;
            TagManager.dataLayer(tagManagerArgs);
        });

    }
    state = {
        initRoute: null
    };

    static contextTypes = {
        router: () => null, // replace with PropTypes.object if you use them
    };

    componentDidMount() {
        try {
            const token: any = localStorage.getItem('current-user');
            if (token) {
                const { role } = JSON.parse(token);
                if (role === ROLE_SUPERUSER) {
                    this.setState({
                        initRoute: 'Clients'
                    });
                }
                else if (role === ROLE_ADMIN) {
                  this.setState({
                      initRoute: 'Sites'
                  });
              }
            }
        } catch (e) {
            console.error('unable to read user role from session ', e)
        }
    }

    render() {
        const authed = !!(localStorage.token && localStorage.token.length > 0);
        const { initRoute } = this.state;
        const startRoute = initRoute === 'Sites' ? Sites : (initRoute === 'Clients' ? Clients : Candidates);
        
        return (
            <Fragment>
                <Helmet><html lang={'en-GB' || 'en-GB'} /></Helmet>
                <Suspense fallback={<Loading />}>
                    <Switch>
                        {/* Any unauthorised route (or exactly to '/login') hits the login page */}
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/reset-password" component={ResetPassword} />

                        <PrivateRoute authorised={authed} exact path="/" component={startRoute} />

                        <PrivateRoute authorised={authed} exact path="/sites" component={Sites} />
                        <PrivateRoute authorised={authed} exact path="/sites/edit/:id" component={EditSite} />
                        <PrivateRoute authorised={authed} exact path="/sites/create" component={CreateSite} />
                        <PrivateRoute authorised={authed} exact path="/sites/import" component={ImportSites} />

                        <PrivateRoute authorised={authed} exact path="/studies" component={Studies} />
                        <PrivateRoute authorised={authed} exact path="/studies/edit/:id" component={EditStudy} />
                        <PrivateRoute authorised={authed} exact path="/studies/create" component={CreateStudy} />

                        <PrivateRoute authorised={authed} exact path="/call-centres" component={CallCentres} />
                        <PrivateRoute authorised={authed} exact path="/call-centres/edit/:id" component={EditCallCentre} />
                        <PrivateRoute authorised={authed} exact path="/call-centres/create" component={CreateCallCentre} />

                        <PrivateRoute authorised={authed} exact path="/my-profile" component={MyProfile} />

                        <PrivateRoute authorised={authed} exact path="/users/:filter" component={Users} />
                        <PrivateRoute authorised={authed} exact path="/users/:type/edit/:id" component={EditUser} />
                        <PrivateRoute authorised={authed} exact path="/users/edit/:id" component={EditUser} />
                        <PrivateRoute authorised={authed} exact path={`/users/:type/import`} component={ImportUsers} />

                        <PrivateRoute authorised={authed} exact path="/clients" component={Clients} />
                        <PrivateRoute authorised={authed} exact path="/clients/edit/:id" component={EditClient} />
                        <PrivateRoute authorised={authed} exact path="/clients/create" component={CreateClient} />

                        <PrivateRoute authorised={authed} exact path="/candidates/edit/:id" component={EditCandidate} />
                        <PrivateRoute authorised={authed} exact path="/candidates/create" component={CreateCandidate} />
                        <PrivateRoute authorised={authed} exact path="/candidates/:id/:filter" component={Candidates} />

                        <PrivateRoute authorised={authed} exact path="/data-export" component={DataExport} />
                        <PrivateRoute authorised={authed} exact path="/support" component={Support} />
                        <PrivateRoute authorised={authed} exact path="/performance-dashboard" component={StudySelection} />
                        <PrivateRoute authorised={authed} exact path="/performance-dashboard/:studyId/all" component={PerformanceDashboard} />
                        <PrivateRoute authorised={authed} exact path="/csv-upload/:clientId/:studyId" component={CsvUpload} />
                        <PrivateRoute authorised={authed} exact path={myCandidates.url} component={StudySelection} />

                        <PrivateRoute authorised={authed} path="/" component={FourOhFour} />
                    </Switch>
                </Suspense>
            </Fragment>
        );
    }
}

export default withRouter(Main);

import { CANDIDATE_STATUS_EXCLUDED } from '../candidateStatus';

const EnglishUK = {
  pages: {
    login: {
      title: 'Log in',
    },
    resetPassword: {
      title: 'Reset your password',
    },
    dashboard: {
      title: 'Dashboard',
      view: 'View my candidates',
    },
    admins: {
      title: 'All Administrators',
      view: 'View all administrators',
      button: {
        create: {
          label: 'Add a new admin.',
          copy: 'Add a new admin',
        },
      },
    },
    users: {
      title: 'All Users',
      view: 'View all users',
      button: {
        create: {
          label: 'Add a new user.',
          copy: 'Add a new user',
        },
      },
      import: {
        success: 'The users import was successful'
      }
    },
    'users-edit': {
      title: 'Edit user details',
      view: 'Edit user details',
    },
    users_create: {
      title: 'Create a user',
      view: 'Create a user',
    },
    'users-import-site-staff': {
      title: 'Upload Site Staff Users CSV'
    },
    candidates: {
      title: 'All Candidates',
      view: 'View all candidates',
      button: {
        create: {
          label: 'Add a new candidate.',
          copy: 'Add a new candidate',
        },
      },
      import: {
        success: 'The candidate import was successful'
      }
    },
    mycandidates: {
      title: 'My Candidates',
      view: 'View my candidates',
      button: {
        create: {
          label: 'Add a new candidate.',
          copy: 'Add a new candidate',
        },
      },
    },
    myStudies: {
      title: 'My Studies',
      view: 'View my studies',
      button: {
        create: {
          label: 'Add a new candidate.',
          copy: 'Add a new candidate',
        },
      },
    },
    candidates_create: {
      title: 'Add a new candidate',
    },
    candidates_edit: {
      title: 'Edit candidate data',
    },
    investigators: {
      title: 'All Investigators',
      view: 'View all investigators',
      button: {
        create: {
          label: 'Add a new investigator.',
          copy: 'Add a new investigator',
        },
      },
    },
    investigators_create: {
      title: 'Add a new investigator',
    },
    investigators_edit: {
      title: 'Edit investigator data',
    },
    clients: {
      title: 'All Clients',
      view: 'View all clients',
      button: {
        create: {
          label: 'Add a new client.',
          copy: 'Add a new client',
        },
      },
    },
    admin: {
      title: 'Administrators',
      view: 'View administrators',
      button: {
        create: {
          label: 'Add a new admin.',
          copy: 'Add a new admin',
        },
      },
    },
    'admin-edit': {
      title: 'Edit admin details',
    },
    'admin-create': {
      title: 'Add a new admin',
    },
    'clients-create': {
      title: 'Add a new client',
    },
    clients_edit: {
      title: 'Edit client details',
    },
    sites: {
      title: 'All Sites',
      view: 'View all sites',
      button: {
        create: {
          label: 'Add a new site.',
          copy: 'Add a new site',
        },
      },
      import: {
        success: 'The sites import was successful'
      },
      titles: {
        edit: 'Edit site details',
        create: 'Add a new site',
      }
    },
    'sites-create': {
      title: 'Add a new site',
    },
    'sites-edit': {
      title: 'Edit site details',
    },
    'sites-import': {
      title: 'Upload Sites CSV'
    },
    mySites: {
      title: 'My Sites',
      view: 'View my sites',
      button: {
        create: {
          label: 'Add a new site.',
          copy: 'Add a new site',
        },
      },
    },
    studies: {
      title: 'All Studies',
      view: 'View all studies',
      button: {
        create: {
          label: 'Add a new study.',
          copy: 'Add a new study',
        },
      },
    },
    dataExport: {
      title: 'Data Export',
      view: 'View all export'
    },
    support: {
      title: 'Need support?',
      view: 'Need support?'
    },
    performanceDashboard: {
      title: 'Performance Dashboard',
      view: 'Performance Dashboard',
      error: 'Sorry, Performance Dashboard has not been set up for this study.',
    },
    splashScreen: {
      title: 'Please select a study',
      view: 'View all export'
    },
    'studies-edit': {
      title: 'Edit study details',
    },
    'studies-create': {
      title: 'Add a new study',
    },
    'call-centres': {
      title: 'All Call Centres',
      view: 'View all call centres',
      button: {
        create: {
          label: 'Add a new call centre.',
          copy: 'Add a new call centre',
        },
      },
    },
    'call-centre-edit': {
      title: 'Edit Call Centre',
      view: 'Edit call centre',
    },
    'call-centre-create': {
      title: 'Create New Call Centre',
      view: 'Create new call centre',
    },
    'my-profile': {
      title: 'My Profile',
      view: 'View my profile',
    },
    fourohfour: {
      title: 'Oops! This page doesn\'t exist.',
      copy: 'You seem to have found a page that doesn\'t exist. Try using the menu to navigate around.',
    },
    csvUpload: {
      title: 'CSV upload',
      view: 'Upload your csv here',
    },
  },
  actions: {
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    view: 'View',
    submit: 'Submit',
    save: 'Save',
    send: 'Send',
    saveNote: 'Save Note',
    cancel: 'Cancel',
    close: 'Close',
    reset: 'Reset',
    positive: 'Yes',
    confirm: 'Confirm',
    negative: 'No',
    login: 'Log in',
    logout: 'Log out',
    resetPassword: 'Reset password',
  },
  statuses: {
    'new-candidate': 'New candidate',
    registered: 'Registered',
    preScreened: 'Pre-screened',
    bookedScreening: 'Booked screening',
    consented: 'Consented',
    enrolled: 'Enrolled',
    excluded: CANDIDATE_STATUS_EXCLUDED,
  },
  headers: {
    age: 'Age',
    campaignContent: 'Campaign content',
    campaignMedium: 'Campaign medium',
    campaignName: 'Campaign name',
    campaignSource: 'Campaign source',
    campaignTerm: 'Campaign term',
    condition: 'Days since status updated',
    contactNumber: 'Phone Number',
    created: 'Submission date/time',
    'date-applied': 'Date Applied',
    dob: 'Date of birth',
    email: 'Email address',
    firstName: 'First name',
    lasttName: 'Last name',
    formQuestionResponses: 'Questions',
    id: 'Candidate ID',
    ivrsCode: 'IVRS code',
    notes: 'Notes',
    screeningQuestionResponses: 'Screening questions',
    screenVisitDate: 'Screen visit date',
    gender: 'Sex',
    role: 'Role',
    enabled: 'Enabled',
    status: 'Status',
    priorityStatus: 'Priority',
    country: 'Country',
    siteIdentifier: 'Site ID',
    siteRegion: 'Region',
    utmSource: 'UTM Source',
    utmMedium: 'UTM Medium',
    utmFormat: 'UTM Format',
    studySiteId: 'Preferred site',
    surname: 'Surname',
    utmData: 'UTM data',
    websiteUrl: 'Website',
    validated: 'Validated',
    rejected: 'Rejected',
    dropped: 'Dropped',
  },
  summaryBlocks: {
    newReferrals: {
      name: 'newReferrals',
      title: 'New Referrals',
      description: 'Count of records with the status ‘Registered',
      theme: 'success',
    },
    overdueReferrals: {
      name: 'overdueReferrals',
      title: 'Overdue Referrals',
      description: 'Count of candidates who have status ‘Registered’ for >2 days*',
      theme: 'failure',
    },
    lateScreenings: {
      name: 'lateScreenings',
      title: 'Late Screenings',
      description: 'Count of candidates who have status ‘Screening booked’ beyond their screening date*',
      theme: 'none',
    },
    consentUpdates: {
      name: 'consentUpdates',
      title: 'Consent Updates',
      description: 'Count of candidates who have status ‘Consented',
      theme: 'none',
    },
    randomisationUpdates: {
      name: 'randomisationUpdates',
      title: 'Randomisation updates',
      description: 'Count of candidates who have status ‘Enrolled',
      theme: 'none',
    },
  },
  formFields: {
    contactNumber: {
      description: 'We need this to contact you',
      placeholder: '0161 225 1234',
    },
    county: {
      description: '',
      placeholder: 'La France',
    },
    created: {
      description: '',
      placeholder: '',
    },
    clientForm: {
      name: {
        description: 'Client Name',
        placeholder: 'Enter client Name',
      },
      contactName: {
        description: 'Contact Name',
        placeholder: 'Enter contact name',
      },
      contactEmail: {
        description: 'Contact Email',
        placeholder: 'Enter contact email',
      },
    },
    dateApplied: {
      description: '',
      placeholder: '',
    },
    dob: {
      description: 'We need this to place you in a relevant study',
      placeholder: 'Jan 20th 1979',
    },
    email: {
      description: 'We need this to contact you',
      placeholder: 'firstname.lastname@domain.tld',
    },
    firstName: {
      description: 'We need this to help identify you',
      placeholder: 'John',
    },
    lastName: {
      description: 'We need this to help identify you',
      placeholder: 'John',
    },
    formQuestionResponses: {
      description: '',
      placeholder: '',
    },
    id: {
      description: 'We need this to help identify you',
      placeholder: '',
    },
    ivrsCode: {
      description: '',
      placeholder: '1-234-56',
    },
    notes: {
      description: '',
      display: 'Add new note',
      placeholder: 'Enter new note',
    },
    postcode: {
      description: 'May be used to assist delivery',
      placeholder: 'M1 7EP',
    },
    studySiteId: {
      description: '',
      placeholder: '',
    },
    region: {
      description: 'May be used to assist delivery',
      placeholder: 'UK',
    },
    screeningQuestionResponses: {
      description: '',
      placeholder: '',
    },
    screenVisitDate: {
      description: '',
      placeholder: '',
    },
    gender: {
      description: 'We need this to place you in a relevant study',
      placeholder: '',
      options: {
        male: 'Male',
        female: 'Female',
      },
    },
    status: {
      description: '',
      placeholder: '',
    },
    streetAddressFirst: {
      description: 'Street and number, PO Box, c/o',
      placeholder: '54 Princess Street',
    },
    streetAddressSecond: {
      description: 'Flat, suite, unit, building, floor, etc',
      placeholder: 'Lx Labs, First Floor',
    },
    study: {
      description: '',
      placeholder: '',
    },
    studyForm: {
      client: {
        description: 'Client',
      },
      name: {
        description: 'Study Name',
        placeholder: 'Enter Study Name',
      },
      description: {
        description: 'Study Description',
        placeholder: 'Enter Study Description',
      },
      slug: {
        description: 'Slug',
        placeholder: 'Enter Slug',
      },
      url: {
        description: 'Recruitment site URL',
        placeholder: 'Enter Recruitment site URL',
      },
    },
    siteForm: {
    },
    surname: {
      description: 'We need this to help identify you',
      placeholder: 'Smith',
    },
    townCity: {
      description: '',
      placeholder: 'Manchester',
    },
    utmData: {
      description: '',
      display: 'thomasxbanks',
      placeholder: '',
    },
  },
  messages: {
    resetPassword: '',
    generic_error: 'Something has gone very, very wrong!',
    required: 'Required',
    zero_records: 'There are no results.',
    missing_data: 'TBC',
    login: {
      before: 'Log in to your account.',
      during: 'Logging in. Please wait.',
    },
    logout: {
      before: 'Log out of your account',
      confirm: 'Are you sure you want to log out? There is no undo on this option - you will need to log back in.',
      yes: 'Yes, log out',
      no: 'No, don\'t log out',
    },
    password: {
      reset: 'Reset password',
      success: 'Password Reset email sent',
      failure: 'There has been an error sending your Password Reset email. Please try again',
      notFound: 'User does not exist',
    },
    welcome: 'Welcome back!',
    view_profile: 'View full profile',
    flaring_reminder: 'Flaring Reminder Email',
    save: {
      success: 'Saved',
      failure: 'Not saved',
    },
    sendEmail: {
      areSure: 'Are you sure you want to send an email to this candidate.',
      prevContact: 'Days since last contact:',
    },
    loading: 'Loading',
  },

};

export default EnglishUK;

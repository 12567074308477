import * as React from 'react';
import { NavLink, Redirect } from 'react-router-dom';

import Icon from 'components/@shared/icons';
import ROUTES from '@constants/routes';
import { myCandidates } from '@constants/menu';
import { ROLE_ADMIN } from '@helpers/RoleTypes';

import './style.scss';

const { Fragment } = React;
interface Props {
  menus?: any;
}

interface NavItem { label: string | undefined; url: string; icon: any; copy: React.ReactNode; }

const Navigation = (props: Props) => {

  if (window.location.pathname === ROUTES.USERS.index) {
    return <Redirect to={`${ROUTES.USERS.index}/${ROLE_ADMIN}`} />;
  }
  const { menus } = props;
  const navItems = menus;
  if (!navItems || !navItems.length) {
    return <Fragment />;
  }

  const navigation: any = [];
  navItems.forEach((navItem: NavItem, index: number) => {
    let LinkProps: any = {
      role: 'menuitem',
      'aria-label': navItem.label,
      className: 'nav-item',
      activeClassName: 'active',
      exact: navItem.url === '/',
      to: navItem.url,
      title: navItem.label,
      tabIndex: index === 0 ? '0' : '-1'
    };

    navigation.push(
      <li
        role="none"
        key={`nav-key___${index}`}
      >
        <NavLink
          {...LinkProps}
        >
          {navItem.icon && <Icon name={navItem.icon} />}
          <span
            role="link"
            aria-label={navItem.label}
            title={navItem.label}
          >
            {navItem.copy}
          </span>
        </NavLink>
      </li>,
    );
  });

  return (
    <Fragment>
      <nav
        role="navigation"
        aria-label={'primary menu'}
        className="navigation"
      >
        <ul
          role="menubar"
          aria-label="Menu List"
          className="navigation_list"
        >
          {navigation}
        </ul>
      </nav>
    </Fragment>
  );
}

export default Navigation;
